declare module '@nuxt/schema' {
  interface PublicRuntimeConfig {
    recaptchaSiteKey: string
  }
}

/* eslint-disable import/first */
import type { PublicRuntimeConfig, RuntimeConfig } from '@nuxt/schema'
import { DRUPAL_HOST, IS_DEV, ROKKA_HOST } from '../helpers/env'

const recaptchaSiteKey = process.env.RECAPTCHA_SITE_KEY || ''

type PartialPublicRuntimeConfig = Omit<PublicRuntimeConfig, 'rokkaHost'> & {
  gtm: { id: string; debug: boolean; enabled: boolean }
  datadogToken?: string
}

const publicRuntimeConfig: PartialPublicRuntimeConfig = {
  // This requires a environment variable to be set in platform with the following name:
  // env:NUXT_PUBLIC_ROKKA_HOST
  // Example value: oris-testing.rokka.io
  rokkaHost: ROKKA_HOST,
  buildHash: process.env.PLATFORM_TREE_ID || Date.now().toString(),
  isDevelopment: IS_DEV,
  isTesting: IS_DEV,
  recaptchaSiteKey,
  datadogToken: process.env.NUXT_PUBLIC_DATADOG_TOKEN || '',
  gtm: {
    id: 'GTM-NQTW25',
    debug: false,
    enabled: false,
  },
}

export const runtimeConfig: {
  backendUrl: RuntimeConfig['backendUrl']
  public: PartialPublicRuntimeConfig
} = {
  backendUrl: DRUPAL_HOST,
  public: publicRuntimeConfig,
}
