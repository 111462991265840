import provideState_MaX9fQr3GV from "/builds/oris/oris-web/frontend/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.js";
import revive_payload_client_4sVQNw7RlN from "/builds/oris/oris-web/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/oris/oris-web/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/oris/oris-web/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/oris/oris-web/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/oris/oris-web/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/oris/oris-web/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/oris/oris-web/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/builds/oris/oris-web/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/oris/oris-web/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/oris/oris-web/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import blokkliEditable_4phZLIxAsp from "/builds/oris/oris-web/frontend/node_modules/@blokkli/editor/dist/runtime/plugins/blokkliEditable.js";
import texts_P4dMAr3Ct0 from "/builds/oris/oris-web/frontend/node_modules/nuxt-easy-texts/dist/runtime/plugins/texts.mjs";
import clickaway_ZmIhydr7La from "/builds/oris/oris-web/frontend/plugins/clickaway.ts";
import analyticsDefaultConsent_client_dNkvPS29yU from "/builds/oris/oris-web/frontend/plugins/analyticsDefaultConsent.client.ts";
import plugin_tbFNToZNim from "/builds/oris/oris-web/frontend/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import clientCache_SyeCpR1f31 from "/builds/oris/oris-web/frontend/plugins/clientCache.ts";
import graphqlMiddleware_2xI7SMabjg from "/builds/oris/oris-web/frontend/plugins/graphqlMiddleware.ts";
import staticNodes_n2RLvQu6RX from "/builds/oris/oris-web/frontend/plugins/staticNodes.ts";
import cart_client_d2trXyAyXb from "/builds/oris/oris-web/frontend/plugins/cart.client.ts";
import datadog_client_cfAAU11B0P from "/builds/oris/oris-web/frontend/plugins/datadog.client.ts";
export default [
  provideState_MaX9fQr3GV,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  blokkliEditable_4phZLIxAsp,
  texts_P4dMAr3Ct0,
  clickaway_ZmIhydr7La,
  analyticsDefaultConsent_client_dNkvPS29yU,
  plugin_tbFNToZNim,
  clientCache_SyeCpR1f31,
  graphqlMiddleware_2xI7SMabjg,
  staticNodes_n2RLvQu6RX,
  cart_client_d2trXyAyXb,
  datadog_client_cfAAU11B0P
]