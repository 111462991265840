import { datadogLogs } from '@datadog/browser-logs'

export default defineNuxtPlugin(async () => {
  // Disable during local development.
  if (import.meta.dev) {
    return
  }

  const { datadogToken: token, buildHash } = useRuntimeConfig().public

  if (token) {
    const drupalUser = await useDrupalUser()
    datadogLogs.setGlobalContextProperty('commit', buildHash)
    datadogLogs.setGlobalContextProperty(
      'is_editor',
      drupalUser.value.canAccessToolbar,
    )

    datadogLogs.init({
      clientToken: token,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    })
  }
})
